import React from 'react';
import { Col } from "react-bootstrap";

const ListItemJob = ({ job, index }) => {
  const isActive = index <= 5;
  return (
    <Col
      md={12}
      lg={12}
      className="px-2 mb-3 mh-200"
      key={`list-job-${index}`}
      id={`list-job-${index}`}
    >

      <a href={"/" + job.url}
        target="_blank"
        rel="noreferrer"
        role="button"
        className={isActive ? "offer-link" : "disabled-offer-link"}
      >
        <div className="job-item">
          <img
            alt={`Logo de empleador ${job.employer.name}`}
            width={job.employer.logo_width}
            height={job.employer.logo_height}
            src={job.employer.logo_url}>
          </img>

          <div className='job-info'>
            <span className="job-employer">
              {job.employer.name}
            </span>
            <br />
            <span className="job-title">
              {job.title}
            </span>
            <br />
            <span className="job-subtitle">
              {job.subtitle}
            </span>
          </div>

          <div className="info-xs-hidden">
            <button className='show-offer'>
              Ver más
            </button>
          </div>
        </div>
      </a>

    </Col>
  )
}

export default ListItemJob;
